import axios from 'axios';
import { resolvePath } from 'react-router-dom';
let DEVICE_DATA_MODEL_DEV = '25d910c7-6ee0-429b-9dd3-4a883b116246';
let DEVICE_DATA_MODEL_PRD = '25d910c7-6ee0-429b-9dd3-4a883b116246';
let LOOKUP_DATA_MODEL_DEV = '5aefcc83-336f-463c-ae80-e2ed27ccbe11';
let LOOKUP_DATA_MODEL_PRD = '5aefcc83-336f-463c-ae80-e2ed27ccbe11';
let LOOKUP_PROP_SET_DEV = '7f3f01a8-6fb9-4ab2-86bb-ca4f40f55f21';
let LOOKUP_PROP_SET_PRD = '7f3f01a8-6fb9-4ab2-86bb-ca4f40f55f21';
let BASE_URL = "https://api.galencloud.com"

let X_TENENT_DOMAIN_DEV = 'photonicare-dev.galencloud.com';
let X_TENENT_DOMAIN_PRD = 'photonicare.galencloud.com';

class galenApi {

	static error = {};

	constructor() {
		Object.defineProperies(this.error, { 
			UNKNOWN_API_ERROR_MSG : {
				value : 'Unknown API Error',
				writable : false
			}, AUTH_ERROR : { 
				value : 'Authentication Error',
				writable : false
			}, NOT_FOUND_ERROR : {
				value : 'Component Not found',
				writable : false
			}
		});
	}
	
	// send call with access credentials and return an auth token
	static async login (isProd, username, password) {

		let url = new URL('https://api.galencloud.com/auth/login');
		url.search = new URLSearchParams({ emailAddress : username, password : password });

		try {
			let response = await axios.post(url ,{},{
				headers:{
					'Content-Type': 'application/json',
					'X-TENANT-DOMAIN' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
					'X-API-VERSION' : '3',
					'X-APP-TYPE' : 'Device'
			  },
			});
			if (typeof response.headers.authorization === 'string') {
				// return the authentication token for further use of the API
				return { success:true, token:response.headers.authorization};
			}
		} catch (error) {

			// if invalid response, print error and set token to null
			if (error.response.status === 412) {
				return { success: false, token:null, errorType:this.error.AUTH_ERROR, errorMessage:"Incorrect username or password"}
			}
			else  {
				return { success: false, token:null, errorType:this.error.AUTH_ERROR, errorMessage:"Unknown authentication error"}
			}
		}
	}

	// get a list of practices that the user has permission to see
	static async getPractices (isProd, token) {
		
		let response = null;
		try{
			response = await axios.get('https://api.galencloud.com/user/practice?pageNumber=0&pageSize=1000',{
				headers : {
				'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
				'x-api-version' : 3,
				'x-app-type' : 'Device',
				'authorization': token,
				}
			});
		} catch (e) {
			if (e.response.status === 401) return {success: false, practiceList:[], errorType:this.error.AUTH_ERROR};
			if (e.response.status === 412) return {success: false, practiceList:[], errorType:this.error.AUTH_ERROR};
			else return {success:false, practiceList:[], errorType:this.error.UNKNOWN_API_ERROR_MSG};
		}
		if (response.status !== 200) return {success:false, practiceList:[], errorType:this.error.UNKNOWN_API_ERROR_MSG};

		let practiceList = response.data.content.map((element) => {
			return {key:element.practiceId, value:element.name,};
		});
		
		return {success:true, practiceList: practiceList}
	}

  static async getPracticeId(isProd, token, practiceName) {
    let response = null;
		try{
			response = await axios.get('https://api.galencloud.com/user/practice',{
				headers : {
				'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
				'x-api-version' : 3,
				'x-app-type' : 'Device',
				'authorization': token,
				},
        params: {name: practiceName}
			});
		} catch (e) {
			if (e.response.status === 401) return {success: false, practiceName:"", practiceId:"", errorType:this.error.AUTH_ERROR};
			if (e.response.status === 412) return {success: false, practiceName:"", practiceId:"", errorType:this.error.AUTH_ERROR};
			else return {success:false, practiceName:"", errorType:this.error.UNKNOWN_API_ERROR_MSG};
		}
		if (response.status !== 200) return {success:false, practiceName:"", practiceId:"", errorType:this.error.UNKNOWN_API_ERROR_MSG};

		let practiceInfo = { id: response.data.content[0].practiceId, name: response.data.content[0].name, success: true};
		
		return practiceInfo;
  }

	// get a list of serials within a practice
	static async lookupSerials (isProd, token) {
		let response = null;

		try{
			response = await axios.post('https://api.galencloud.com/data/devicedata-advanced?pageNumber=0&pageSize=10000',
				{
					"deviceDataModelId": isProd ? LOOKUP_DATA_MODEL_PRD : LOOKUP_DATA_MODEL_DEV,
					"deviceCriteria": null,
					"devicePropertyCodes":["Deviceid","Serial"],
			},
			{
				headers : {
					'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
					'x-api-version' : 3,
					'x-app-type' : 'Device',
					'authorization': token,
				}
			});
		} catch (e) {
			return {success: false, snLookupList: [], errorType:this.error.UNKNOWN_API_ERROR_MSG};
		}

		if (response.status !== 200) return  {success: false, snLookupList: [], errorType:this.error.UNKNOWN_API_ERROR_MSG};;
	
		let snList = response.data.content.map((element) => {
			return {serial: element.data.Serial.value,deviceId:element.data.Deviceid.value};
		});

		return { success: true, snLookupList: snList}
	} 

	static async getDevices (isProd, token, practice) {
		let response = null;
		if (practice.id === null) practice = await this.getPracticeId(isProd, token, practice.name);
		try{
			response = await axios.post('https://api.galencloud.com/data/devicedata/aggregate?pageNumber=0&pageSize=10000',
			{
        "deviceDataModelId": isProd ? DEVICE_DATA_MODEL_PRD : DEVICE_DATA_MODEL_DEV,
        "dataPropertyCode": "Deviceid",
        "options": {
          "propertyCode": "Deviceid",
          "aggregationType": "Count"
        },
        "ownerFilter":{
          "users":[],
          "practices": practice.id !== null ? [practice.id] : [],
          "suppliers":[]
        }
			},
			{
				headers : {
					'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
					'x-api-version' : 3,
					'x-app-type' : 'Device',
					'authorization': token,
				}
			});
		} catch (e) {
			return {success: false, deviceList: [], errorType:this.error.UNKNOWN_API_ERROR_MSG};
		}

		if (response.status !== 200) return  {success: false, deviceList: [], errorType:this.error.UNKNOWN_API_ERROR_MSG};;
	
		let deviceList = response.data.content.map((element) => {
			return {value: element.view.bucket,key:element.view.bucket};
		})

		return { success: true, deviceList: deviceList}
	}

	static async getExams (isProd, token, practice, deviceId) {

		let response = null;

		try{
			response = await axios.post('https://api.galencloud.com/data/devicedata-advanced?pageNumber=0&pageSize=1000&sortBy=data.Examdate.value&sortOrder=DESC',
			{
				"deviceDataModelId": isProd ? DEVICE_DATA_MODEL_PRD : DEVICE_DATA_MODEL_DEV,
			
				"devicePropertyCodes": [
					"Deviceid",
					"Examnumber",
					"Recordingnumber",
					"Examtime",
					"Examdate"
				],
				"deviceCriteria": [
					{
						"key": "Deviceid",
						"operator": "Equal",
						"value": deviceId.name,
					},
					{
						"key": "Surface",
						"operator": "IsSet",
					},
					{
						"key": "Lci",
						"operator": "IsSet",
					},
				]
			},
			{
				headers : {
					'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
					'x-api-version' : 3,
					'x-app-type' : 'Device',
					'authorization': token,
				}
			});
		} 
		catch (e) {
			if (e.response.status === 401 || e.response.status === 412) {
				return {success: false, examList : [], errorType: this.error.AUTH_ERROR}
			} 
			else {
				return {success: false, examList : [], errorType: this.error.UNKNOWN_API_ERROR_MSG}
			}
		}

		// create a list to be displayed to user showing the examnumber
		let examList = response.data.content.map((element) => {
			return {
				value: element.data.Examnumber.value + element.data.Examdate.value + element.data.Examtime.value, 
				examNumber: element.data.Examnumber.value,
				examDate:element.data.Examdate.value, 
				examTime:element.data.Examtime.value, 
				key:element.deviceDataId
			};
		});

		return { success: true, examList: examList };
	}

	static async getImageUrl (isProd, token, deviceDataId, imagePropertyCode) {
	
		let imageType;
		if (imagePropertyCode === 'surface') imageType = 'Surface';
		else if (imagePropertyCode === 'lci') imageType = 'Lci'

		let url = new URL('https://api.galencloud.com/data/devicedata-media/' + 
					(isProd ? DEVICE_DATA_MODEL_PRD : DEVICE_DATA_MODEL_DEV) + 
					'/' + deviceDataId +'/' + imageType);

		try {
			let response = await axios.get(url ,{
			headers:{
				'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
				'x-api-version' : 3,
				'x-app-type' : 'Device',
				'authorization': token,
			},
			responseType:'blob',
			});

			// if invalid response, print error and set token to null
			if (response.status !== 200) return {success : false, errorType : this.error.UNKNOWN_API_ERROR_MSG };

			return {success: true , url: response.data}

		} catch (e) {
			// if an image is not found at expected location, return null
			if (e.response.status === 404) return {success: false, url : null, errorType:this.error.NOT_FOUND_ERROR};
			// for any other kind of failure, return error
			else return {success: false, url : null, errorType:this.error.UNKNOWN_API_ERROR_MSG};
		}
	}

	static async getRecordings (isProd, token, deviceId, examNumber, examDate, examTime) {
		
		let response;
		// If older format url, no date and time will be provided. This means exams can not show properly
		// if multiple matches, but try to handle anyway by searching without date and time and returning
		// earliest result
		let examDateFilter = examDate.name ?
		{
			"key": "Examdate",
			"operator": "Equal",
			"value": examDate.name
		} :
		{};
		let examTimeFilter = examTime.name ?
		{
			"key": "Examtime",
			"operator": "Equal",
			"value": examTime.name
		} :
		{};
		try{
			let pageSize = "pageNumber=0&pageSize=1000"
			let sort = "sortBy=data.value.examtime&sortOrder=ASC"
			let url = `${BASE_URL}/data/devicedata-advanced?${sort}&${pageSize}`
			response = await axios.post(url,
			{
				"deviceDataModelId": isProd ? DEVICE_DATA_MODEL_PRD : DEVICE_DATA_MODEL_DEV,
			
			  	"devicePropertyCodes": [
					"Deviceid",
					"Examnumber",
					"Recordingnumber",
					"Examtime",
					"Examdate",
					"Version",
					"Lci",
					"Surface",
					"Surfacetimestamps"
			  	],
			  	"deviceCriteria": [
				{          
					"key": "Deviceid",
					"operator": "Equal",
					"value": deviceId.name 
				},
				{
					"key": "Examnumber",
					"operator": "Equal",
					"value": parseInt(examNumber.name)
				},
				examDateFilter,
				examTimeFilter,
				{
					"key": "Surface",
					"operator": "IsSet",
				},
				{
					"key": "Lci",
					"operator": "IsSet",
				},
			  ]
			}
		  	,
			{
				headers : {
				  'x-tenant-domain' : isProd ? X_TENENT_DOMAIN_PRD : X_TENENT_DOMAIN_DEV,
				  'x-api-version' : 3,
				  'x-app-type' : 'Device',
				  'authorization': token,
				}
			});
		} catch (e) {
			// if an exam is not found, return an empty list (not considering this an error)
			if (e.response.status === 404) return {success: true, recordingList : []};
			// handle auth errors
			if (e.response.status === 401 || e.response.status === 412) {
				return {success: false, examList : [], errorType: this.error.AUTH_ERROR}
			} 
			// for any other kind of failure, return error
			else return {success: false, recordingList : [], errorType:this.error.NOT_FOUND_ERROR};
		}
		// if we get an unexpected response code, report an error
		if (response.status !== 200) return {success: false, recordingList : [], errorType:this.error.UNKNOWN_API_ERROR_MSG};
		
		let recordingList = response.data.content.map((element) => {
			return {
				deviceDataId:element.deviceDataId,
				deviceId : element.data.hasOwnProperty('Deviceid') ? element.data.Deviceid.value :null,
				examNumber: element.data.hasOwnProperty('Examnumber') ? element.data.Examnumber.value :null, 
				recordingNumber : element.data.hasOwnProperty('Recordingnumber') ? element.data.Recordingnumber.value : null,
				examTime : element.data.hasOwnProperty('Examtime') ? element.data.Examtime.value : null,
				examDate : element.data.hasOwnProperty('Examdate') ? element.data.Examdate.value : null,
				version : element.data.hasOwnProperty('Version') ? element.data.Version.value : null,
				lci : element.data.hasOwnProperty('Lci') ? element.data.Lci.value : null,
				surface : element.data.hasOwnProperty('Surface') ? element.data.Surface.value : null,
				surfaceTimestamps: element.data.hasOwnProperty('Surfacetimestamps') ? element.data.Surfacetimestamps.value : null,
			};
		});

		return {success:true, recordingList:recordingList}
	}
}

export { galenApi }
