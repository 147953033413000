import React from 'react';
import css from './../css/select_exam.module.css';
import { removeDuplicates } from '../utility/helper_functions.js';
import { galenApi } from '../utility/galen-api.js';

class ExamSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = { selections: { practice: this.props.selections.practice, device: this.props.selections.device, exam: this.props.selections.exam } };
	}

	handleSelect = (componentType, selection) => {
		let selections = { ...this.state.selections };
		selections[componentType] = selection;

		if (componentType === "device") {
			selections.exam = { name: null, id: null };
		}
		else if (componentType === "practice") {
			selections.device = { name: null, id: null };
			selections.exam = { name: null, id: null };
		}
		
		this.setState({ selections: selections });
	}

	updateUrl = () => {
		let selections = this.state.selections;
			let url = window.location.origin +'/examdata2/';
			if(selections.practice.name !== null ) {
				url += selections.practice.name;
				if (selections.device.name !== null) {
					url += '/' + selections.device.name;
					if (selections.exam.name !== null) {
						url += '/' + selections.exam.name;
						if (selections.exam.date !== null) {
							url += '/' + selections.exam.date;
							if (selections.exam.time !== null) {
								url += '/' + selections.exam.time;
							}
						}
					}
				}
			}
			window.history.pushState(null, null, url);
	}

	componentDidUpdate(previousProps, previousState) {
		console.log("here");
		// update url bar with selected practice, device and exam,
		// update url bar with more information, including date and time,
		// changed examData url part to examdata2.
		if (this.state.selections !== previousState.selections) {
			this.updateUrl();
		}

		// if we have a selected exam, send selected exam up to examviewer
		if (this.state.selections.exam.name !== null && !this.props.examSelected)
			this.props.setExam({
				practice: this.state.selections.practice,
				deviceId: this.state.selections.device,
				examNumber: this.state.selections.exam,
				examDate: {name: this.state.selections.exam.date},
				examTime: {name: this.state.selections.exam.time}
			});
	}

	componentDidMount() {
		//window.history.pushState(null,null,window.location.origin  + '/examdata2/');
		this.updateUrl();

	}

	render() {
		return (
			<div className={css.select_exam_window}>
				<SelectComponent className={css.select_component} isProd={this.props.isProd} authToken={this.props.authToken} componentType="practice" handleUnauthorized={this.props.handleUnauthorized} handleSelect={this.handleSelect} examSelected={this.props.examSelected} selections={this.state.selections}></SelectComponent>
				<SelectComponent className={css.select_component} isProd={this.props.isProd} authToken={this.props.authToken} componentType="device" handleUnauthorized={this.props.handleUnauthorized} handleSelect={this.handleSelect} examSelected={this.props.examSelected} selections={this.state.selections}></SelectComponent>
				<SelectComponent className={css.select_component} isProd={this.props.isProd} authToken={this.props.authToken} componentType="exam" handleUnauthorized={this.props.handleUnauthorized} handleSelect={this.handleSelect} examSelected={this.props.examSelected} setExam={this.props.setExam} selections={this.state.selections}></SelectComponent>
			</div>
		)
	}
}

export { ExamSelector }

class SelectComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = { optionList: [] };
	}

	populatePracticeOptions = async () => {

		let response = await galenApi.getPractices(this.props.isProd, this.props.authToken);
		if (!response.success) {
			if (response.errorType === galenApi.error.AUTH_ERROR) this.props.handleUnauthorized();
			else this.setState({ error: true });
			return;
		}
		this.setState({ optionList: response.practiceList });
	}

	populateDeviceOptions = async () => {

		let snLookupList = await (await galenApi.lookupSerials(this.props.isProd, this.props.authToken, [])).snLookupList;
		let response = await galenApi.getDevices(this.props.isProd, this.props.authToken, this.props.selections.practice);
		if (!response.success) {
			if (response.errorType === galenApi.error.AUTH_ERROR) this.props.handleUnauthorized();
			else this.setState({ error: true });
			return;
		}

		let deviceList = response.deviceList;

		for (let i = 0; i < deviceList.length; i++) {
			let lookedUpSerial = snLookupList.find(element => element.deviceId === deviceList[i].key);
			if (lookedUpSerial !== undefined) deviceList[i].serial = lookedUpSerial.serial;
		}

		this.setState({
			optionList: deviceList
		});
	}

	populateExamOptions = async () => {

		let response = await galenApi.getExams(this.props.isProd, this.props.authToken, this.props.selections.practice, this.props.selections.device);
		// remove any duplicate entries with matching exam number since we want to show a unique list of exams to the user
		// here, we check exam number, data and time.
		if (!response.success) {
			if (response.errorType === galenApi.error.AUTH_ERROR) this.props.handleUnauthorized();
			return;
		}
		let examList = removeDuplicates(response.examList, ['value', 'examDate', 'examTime'], "all");
		// parse examDate and examTime to actual exam time. 
		const parseExamDateTime = (exam) => {
			let examDate = exam.examDate;
			let examTime = exam.examTime;
			return new Date(`${examDate} ${examTime}`);
		};
		// sort by exam number in descending order of time.
		examList.sort((a,b) => {return parseExamDateTime(b)-parseExamDateTime(a)});
		this.setState({optionList : []});
		this.setState({ optionList : examList });
	}

	clearOptions = async () => {
		this.setState({ optionList: [] });
	}

	componentDidMount = () => {

		//get list of accessible practices
		if (this.props.componentType === "practice") this.populatePracticeOptions();

		// if selected practice is already available, load devices
		if (this.props.componentType === "device") {
			if (this.props.selections.practice.name !== null) this.populateDeviceOptions();
		}

		// if selected device is already available, load exams
		else if (this.props.componentType === "exam") {
			if (this.props.selections.device.name !== null) this.populateExamOptions();
		}
	}

	componentDidUpdate = (previousProps) => {

		// get options for devices if practice is set
		if (this.props.componentType === "device") {
			if (this.props.selections.practice !== previousProps.selections.practice) {
				if (this.props.selections.practice.name !== null) {
					this.populateDeviceOptions();
				}
				else {
					this.clearOptions();
				}
			}
		}
		// get options for exams if device is set
		else if (this.props.componentType === "exam") {
			if (this.props.selections.device !== previousProps.selections.device) {
				if (this.props.selections.device.name !== null) {
					this.populateExamOptions();
				}
				else {
					this.clearOptions();
				}
			}
		}
		// if selected, add a selected css class that will shrink the width on mobile. Never shrink exams
		if (this.props.componentType !== 'exam') {
			if (this.props.selections[this.props.componentType].name !== null) {
				document.querySelector('#' + this.props.componentType).classList.add(`${css.component_selected}`);
			}
			else {
				document.querySelector('#' + this.props.componentType).classList.remove(`${css.component_selected}`);
			}
		}
		// if only one option, load this automatically
		// commented out, better to know what exam you are looking at than automatically loading it.
		//if (this.state.optionList.length === 1 && this.props.selections[this.props.componentType].name === null) this.handleSelect({name:this.state.optionList[0].value, id:this.state.optionList[0].key});
	}

	handleSelect = (selected) => {
		//ask parent to store selected option 
		
		if(this.props.componentType==='exam'){
			// if it is and exam, we only use the first 5 characters, which is the exam number
			this.props.handleSelect(this.props.componentType, { name: selected.name, id: selected.id, date: selected.date, time: selected.time });
		}else{
			this.props.handleSelect(this.props.componentType, { name: selected.name, id: selected.id, date: selected.date, time: selected.time });
		}
		
	}

	toggleEnlargedView = (event) => {
		// allow user to toggle width of div if the component has a selection and is not the exam selector
		if (this.props.selections[this.props.componentType].name !== null && this.props.componentType !== 'exam') {
			document.querySelector('#' + this.props.componentType).classList.toggle(`${css.component_selected}`);
		}
	}

	render() {
		let selected = this.props.selections[this.props.componentType].name;
		let optionList = this.state.optionList.map((element) => {

			let deviceName = element.serial ? element.serial : element.value;
			let examNumber = element.serial? element.serial : element.examNumber;
			let selectionInfo = { name: examNumber===undefined?element.value:element.examNumber, id: element.key, date: element.examDate, time: element.examTime };
			
			return (
				<div key={element.value} className={`${css.option} ${selected == element.value ? css.selected : ''}`} onClick={() => this.handleSelect(selectionInfo)}>
					{examNumber===undefined?deviceName:examNumber} {element.examDate} {element.examTime}
				</div>
			);
		});
		return (
			<div className={css.select_component} id={this.props.componentType}>
				<div className={css.select_title} onClick={this.toggleEnlargedView}>{this.props.componentType} </div>
				{optionList}
			</div>
		)
	}
}
